
.loading-icon {
    font-size: 6rem;
    animation: animate 2s infinite;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }